import axios from "axios"

const state=()=>({
    data:[],
    selected:[],
    farmers:[],
    totalmonthProd:"12.77",
    monthProd:"June",
    farmgate:{"farmgatebegin":"","farmgateend":"","imgurl":""},
    topMunicipalProd:[],
    farmSelected:[]
})

const mutations={
    setData(state, payload) {
        state.data = payload
    },
    setSelected(state,payload){
        state.selected=payload
    },

    onSelectFarm(state,payload){
        state.farmSelected=payload
    },
    setFarmers(state,payload){
        state.farmers=payload
    },

    setMonthProd(state,payload){
        state.monthProd=payload
    },
    settotalmonthProd(state,payload){
        state.totalmonthProd=payload
    },
    setFarmgate(state,payload){
        state.farmgate=payload
    },
    setTopMunicipal(state,payload){
        state.topMunicipalProd=payload
    }
}

const actions={
    async getData({commit}){
        await axios.get('/api/livestock').then((res)=>{
            commit('setData',res.data)
        })
    },
    async getDetails({commit,state,rootState})
    {
        await axios.get('/api/livestock/detail/'+state.selected.desc+'/'+state.selected.variety+'/'+rootState.user.id).then((res)=>{
            commit('setFarmers',res.data.data)
            commit('setMonthProd',res.data.nowFullMonth)
            commit('settotalmonthProd',res.data.totalbyMonth)
            commit('setFarmgate',res.data.livestockinfo)
            commit('setTopMunicipal',res.data.dataMunicipal)
        })
    },
    async onSelected({commit},payload){
        commit('setSelected',payload)
    },

    async onSelectFarm({commit},payload){
        commit('onSelectFarm',payload)
    }
}

const getters={
    data:(state)=>{
        return state.data
    },
    getSelected:(state)=>{
        return state.selected
    },
    getFarmers:(state)=>{
        return state.farmers
    },
    getMonthProd:(state)=>{
        return state.monthProd
    },
    getTotalbyMonth:(state)=>{
        return state.totalmonthProd
    },
    getFarmGate:(state)=>{
        return state.farmgate
    },
    getTopMunicipal:(state)=>{
        return state.topMunicipalProd
    },    
    getSelectedFarm:(state)=>{
        return state.farmSelected
    }  
}


export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}