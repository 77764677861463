import axios from "axios";

const state = () => ({
  farmer: [
    {},
  ],
  municipalInfo: {},
  
});

const mutations = {
  setMunicipalInfo(state, payload) {
    state.municipalInfo = payload;
  },
};

const actions = {
    async getMunicipalInfo({commit},payload){
      await axios.get("/api/municipal/"+payload).then((res)=>{
        commit("setMunicipalInfo",res.data)
      })
    }
};

const getters = {

  getmunicipalInfo:(state)=>{
      return state.municipalInfo
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
