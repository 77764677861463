import axios from "axios"

const state=()=>({
    data:[{"cropclass":"AMPALAYA","cropdesc":"CERTIFIED","cropvariety":"","kind":"ORGANIC","available":"10.00"},{"cropclass":"RICE","cropdesc":"HERBS AND SPICES","cropvariety":"NULL","kind":"NON-ORGANIC","available":"270.00"},{"cropclass":"PINEAPPLE","cropdesc":"MESTISA","cropvariety":"","kind":"0","available":"0.25"}],
    selected:[],
    farmers:[],
    totalmonthProd:"12.77",
    monthProd:"June",
    farmgate:{
        "volprod": "0.00",
        "farmgatebegin": "0.00",
        "farmgateend": "0.00"
    },
        topMunicipalProd:[{
            "municipal": "LEON",
            "total": 27.13,
            "unit": "mt"
          },
          {
            "municipal": "Mina",
            "total": 27.13,
            "unit": "mt"
          }],
        farmSelected:[]

})

const mutations={
    setData(state, payload) {
        state.data = payload
    },
    
    setSelected(state,payload){
        state.selected=payload
    },

    setSelectedFarm(state,payload){
        state.farmSelected=payload
    },
    setFarmers(state,payload){
        state.farmers=payload
    },

    setMonthProd(state,payload){
        state.monthProd=payload
    },
    settotalmonthProd(state,payload){
        state.totalmonthProd=payload
    },
    setFarmgate(state,payload){
        state.farmgate=payload
    },
    setTopMunicipal(state,payload){
        state.topMunicipalProd=payload
    }

}

const actions={
    async getData({commit}){
        await axios.get('/api/crops').then((res)=>{
            commit('setData',res.data)
        })
    },

    async getDetails({commit,state,rootState})
    {
        await axios.get('/api/crop/detail/'+state.selected.cropclass+'/'+state.selected.cropdesc+'/'+rootState.user.id).then((res)=>{
            commit('setFarmers',res.data.data)
            commit('setMonthProd',res.data.nowFullMonth)
            commit('settotalmonthProd',res.data.totalbyMonth)
            commit('setFarmgate',res.data.cropinfo)
            commit('setTopMunicipal',res.data.dataMunicipal)
        })
    },

    async onSelected({commit},payload){
        commit('setSelected',payload)
    },

    async onSelectFarm({commit},payload){
        commit('setSelectedFarm',payload)
    }
}

const getters={
    data:(state)=>{
        return state.data
    },
    getSelected:(state)=>{
        return state.selected
    },
    getFarmers:(state)=>{
        return state.farmers
    },
    getMonthProd:(state)=>{
        return state.monthProd
    },
    getTotalbyMonth:(state)=>{
        return state.totalmonthProd
    },
    getFarmGate:(state)=>{
        return state.farmgate
    },
    getTopMunicipal:(state)=>{
        return state.topMunicipalProd
    },    
    getSelectedFarm:(state)=>{
        return state.farmSelected
    },  


}


export default{
    namespaced:true,
    state,
    getters,
    actions,
    mutations
}