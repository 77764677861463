import Vue from 'vue'
import  Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/pages/Login'),
        },
        // Pages
        {
          name: 'AboutUs',
          path: 'aboutus',
          component: () => import('@/views/dashboard/pages/aboutus'),
        },
        {
          name: 'HowtouseSite',
          path: 'howtousesite',
          component: () => import('@/views/dashboard/pages/howtousesite'),
        },
        {
          name: 'ContactUs',
          path: 'contactus',
          component: () => import('@/views/dashboard/pages/contactus'),
        },
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Agricultural Commodities',
          path: 'pages/agriculturalcommodities',
          component: () => import('@/views/dashboard/pages/AgriculturalCommodities'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        {
          name: 'Registration',
          path: '/register',
          component: () => import('@/views/dashboard/pages/Registration'),
        },
        {
          name: 'Login',
          path: '/login',
          component: () => import('@/views/dashboard/pages/Login'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },

       

        {
          name: 'Crop Details',
          path: 'crop/details',
          component: () => import('@/views/dashboard/components/cropdetails'),
        },
        {
          name: 'Livestock Details',
          path: 'livestock/details',
          component: () => import('@/views/dashboard/components/livestockdetails'),
        },
        {
          name: 'Poultry Details',
          path: 'poultry/details',
          component: () => import('@/views/dashboard/components/poultrydetails'),
        },
        {
          name: 'Crop Farmers',
          path: 'crop/farmers',
          component: () => import('@/views/dashboard/components/cropfarmers'),
        },
        {
          name: 'Livestock Farmers',
          path: 'livestock/farmers',
          component: () => import('@/views/dashboard/components/livestockfarmers'),
        },
        {
          name: 'Poultry Farmers',
          path: 'poultry/farmers',
          component: () => import('@/views/dashboard/components/poultryfarmers'),
        },
        {
          name: 'Farmer Details',
          path: 'crop/farmers/details',
          component: () => import('@/views/dashboard/components/farmdetails'),
        },
        {
          name: 'Livestock Farmer Contact',
          path: 'livestock/farmers/details',
          component: () => import('@/views/dashboard/components/livestockfarmercontact'),
        },
        {
          name: 'Poultry Farmer Contact',
          path: 'poultry/farmers/details',
          component: () => import('@/views/dashboard/components/poultryfarmercontact'),
        },
        {
          name: 'Terms & Agreement Policy',
          path: 'termsagreementpolicy',
          component: () => import('@/views/dashboard/pages/TermsAgreementPolicy'),
        },
        { 
          path: '/:pathMatch(.*)*', 
          name:'PageNotFound',
          component: () => import('@/views/dashboard/pages/pagenotfound'),
        }
        
      ],
      
    },
    
  ],
})

