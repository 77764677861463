// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
//
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
//
// * Coded by Creative Tim
//
// =========================================================
//
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import VueToast from 'vue-toast-notification'
import VueMoment from 'vue-moment'
import 'vue-toast-notification/dist/theme-sugar.css';


Vue.use(VueMoment,router)

Vue.use(VueToast,{
  position:'top'
})

Vue.config.productionTip = false

axios.defaults.withCredentials = true
axios.defaults.baseURL= "https://mblapi.agribiziloilo.com"
axios.defaults.xsrfCookieName= 'XSRF-TOKEN'
axios.defaults.xsrfHeaderName= 'X-XSRF-TOKEN'
axios.defaults.headers= {'Content-Type': 'application/json'}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  axios,
  render: h => h(App),
}).$mount('#app')

